<template>
    <div class="order-tabs">
        <p class="tabs-title" v-if="showTitle">最新订单</p>
        <el-tabs
            :value="value"
            type="card"
            @tab-click="handleClick"
            class="tabs-list">
            <el-tab-pane
                v-for="item in tabsList"
                :key="item.id"
                :label="item.label"
                :name="item.value">
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    export default {
        props: {
            value: [ String, Number ],
            tabsList: Array,
            showTitle: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            handleClick(tab) {
                this.$emit('input', tab.name);
                this.$emit('tab-click', tab.name);
            }
        },
    }
</script>

<style scoped>
.tabs-title {
    padding: 16px 0;
    border-bottom: 1px solid #EBEEF5;
    font-size: 18px;
    color: #909399;
}
.tabs-list {
    margin-top: 20px;
}
</style>